<script setup>
import '@webzlodimir/vue-bottom-sheet/dist/style.css'
import { VueBottomSheet } from '@webzlodimir/vue-bottom-sheet'
import { ref } from 'vue'
import { Icon } from '@iconify/vue'
import useAppData from '@/composables/useAppData'
import { useAuthStore } from '@/store/auth'

const authStore = useAuthStore()
const { dashboardMenu, otherProducts } = useAppData()

const myBottomSheet = ref(null)

function open() {
  myBottomSheet.value.open()
}

function close() {
  myBottomSheet.value.close()
}

defineExpose({
  open,
  close,
})
</script>

<template>
  <VueBottomSheet
    ref="myBottomSheet"
    max-width="480px"
    background-scrollable
  >
    <div>
      <div class="flex justify-between items-center px-4 -mt-2">
        <h6 class="text-black-800 font-semibold text-primary">
          Menu Belajar
        </h6>
        <Icon
          icon="ri:close-line"
          class="text-[28px] cursor-pointer text-black-700"
          @click="close"
        />
      </div>

      <div class="flex flex-col gap-4 p-4">
        <div v-if="dashboardMenu?.length">
          <div class="grid grid-cols-4 gap-2">
            <router-link
              v-for="(item, index) in dashboardMenu"
              :key="index"
              class="learning-item"
              :to="item.route"
            >
              <img :src="item.icon" class="h-10 w-10 mb-1.5">

              <h6 class="text-tiny text-black-600 text-center">
                {{ item.name }}
              </h6>
            </router-link>
          </div>
        </div>

        <div v-if="otherProducts?.length">
          <h6 class="font-semibold text-caption mb-2">
            Layanan Lain
          </h6>

          <div class="grid grid-cols-4 gap-2">
            <a
              v-for="(item, index) in otherProducts"
              :key="index"
              :href="`https://${item.domain}?q=${authStore.token}`"
              target="_blank"
              class="learning-item"
            >
              <img :src="item.logo" :alt="`logo-product-${item.id}`" class="h-10 w-10 mb-1.5">

              <h6 class="text-tiny text-black-600 text-center">
                {{ item.name }}
              </h6>
            </a>
          </div>
        </div>
      </div>
    </div>
  </VueBottomSheet>
</template>

<style lang="scss">
.bottom-sheet-bar-menu {
  @apply flex flex-col items-center text-secondary cursor-pointer;
}

.bottom-sheet-bar-menu .menu-icon {
  @apply text-secondary;
}

.bottom-sheet__content {
  height: fit-content !important;
}

.bottom-sheet__backdrop {
  backdrop-filter: blur(1px) !important;
}
</style>
