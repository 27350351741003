<script setup>
import { useRouter } from 'vue-router'
import { Icon } from '@iconify/vue'
import { computed, ref } from 'vue'
import BottomSheetBar from './BottomSheetBar.vue'
import useAppData from '@/composables/useAppData'

const { currentRoute } = useRouter()
const { mobileMenu } = useAppData()

const bottomSheetBar = ref()

const menuList = computed(() => {
  if (mobileMenu.value)
    return mobileMenu.value

  return [
    {
      name: 'Tryout',
      route: '/tryout-dashboard',
      icon: 'ri:draft-fill',
    },
    {
      name: 'Drilling Soal',
      route: '/drilling-soal',
      icon: 'ri:pencil-ruler-2-fill',
    },
    {
      name: 'Dashboard',
      route: '/',
      icon: 'ri:dashboard-fill',
    },
    {
      name: 'Kelas',
      route: '/class',
      icon: 'ri:book-open-fill',
    },
    {
      name: 'Pembelian',
      route: '/payment',
      icon: 'ri:shield-star-fill',
    },
    {
      name: 'Menu Belajar',
      icon: 'mdi:view-grid-outline',
      isIndex: true,
    },
  ]
})

function isActive(route) {
  return route === currentRoute.value.path
}

function showAllIndex() {
  bottomSheetBar.value.open()
}
</script>

<template>
  <div class="block md:hidden fixed bottom-0 left-0 z-30 w-full md:pl-[280px]">
    <div class="jago-bottombar">
      <component
        :is="item.route ? 'router-link' : 'div'"
        v-for="(item, idx) in menuList"
        :key="idx"
        :to="item.route"
        class="jago-bottombar-menu"
        :class="isActive(item.route) ? 'active' : ''"
        @click="item.isIndex ? showAllIndex() : null"
      >
        <Icon
          class="menu-icon text-[28px] mb-1"
          :icon="item.icon"
        />
        <span class="text-tiny">
          {{ item.name }}
        </span>
      </component>
    </div>

    <BottomSheetBar ref="bottomSheetBar" />
  </div>
</template>

<style lang="postcss">
.jago-bottombar {
  @apply bg-white px-2 py-3 m-4 rounded-lg
  flex items-center justify-around shadow-sm;
}

.jago-bottombar-menu {
  @apply flex flex-col items-center text-black-500 cursor-pointer;
}

.jago-bottombar-menu.active {
  @apply text-black-700;
}

.jago-bottombar-menu.active .menu-icon {
  @apply text-primary;
}
</style>
